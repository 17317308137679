import React, { useState, useContext, useEffect, Fragment } from 'react';
import { Button, Paper } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import moment from 'moment';

import {
  getSplit,
  downloadTranscript,
  uploadTranscript,
  saveSplit,
  downloadEditedTranscript,
  cancelSplitClaim
} from 'services/SplitsService';
import { offsetDeadline } from 'services/ClaimsService';
import { updateWrongLanguage } from 'services/InteractionsService';

import { getFileName, doDownload, secondsToHhmmss, isTranscriptExpired, isNonEnglish } from 'utils';
import { interactionCompleted } from 'utils/interaction';
import { isAdmin } from 'utils/roles';
import { DEADLINE_EXTENSION_INCREMENT, SPLIT_HARD_DEADLINE_OFFSET } from 'Constants';

import Deadline from 'components/Deadline';
import ClaimExtensionsViewer from 'components/ClaimExtensionsViewer';
import Heading from 'components/Heading';
import TextField from 'components/TextField';
import { AppContext } from 'AppContext';
import Feedback from 'components/Feedback';
import Transcript from 'components/Transcript';
import Assessment from 'components/Assessment';
import Disclaimer from 'components/Disclaimer';
import LoadingDialog from 'components/LoadingDialog';
import WarningBanner from 'components/WarningBanner';
import WrongLanguage from 'components/WrongLanguage';
import DelayReason from 'components/DelayReason';

export const SplitDetails = (props) => {
  const [split, setSplit] = useState();
  const [loading, setLoading] = useState(false);
  const appContext = useContext(AppContext);

  useEffect(() => {
    const loadSplit = async (id) => {
      try {
        setLoading(true);
        const loadedSplit = await getSplit(id);
        if (!loadedSplit) {
          throw Error(`Could not find split with id ${id}`);
        }
        setSplit(loadedSplit);
      } catch (error) {
        appContext.showNotification('error', error.message);
        props.history.push('/splits');
      } finally {
        setLoading(false);
      }
    };
    loadSplit(props.match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDeadlineExtendable = () => {
    return split.claim.claimer.id === appContext.auth.id;
  };

  const handleDeadlineChange = async (extend) => {
    try {
      setLoading(true);
      const result = await offsetDeadline(
        split.claim.id,
        extend ? DEADLINE_EXTENSION_INCREMENT : -DEADLINE_EXTENSION_INCREMENT
      );
      setSplit({ ...split, claim: result.saved });
      appContext.showNotification('success', 'Deadline modified.');
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSplitCancel = async () => {
    appContext.showDialog(
      'Cancel Split?',
      'Cancelling will delete all transcribing work done on this split and return it to the queue.',
      () => doSplitCancel(),
      "Don't Cancel",
      'Cancel Split'
    );
  };

  const doSplitCancel = async () => {
    try {
      const result = await cancelSplitClaim(split.id);
      setSplit(result.saved);
      appContext.showNotification('success', 'Split cancelled and returned to the TR queue.');
    } catch (error) {
      appContext.showNotification('error', error.message);
    }
  };

  const handleDownloadTranscript = async (edited) => {
    const download = edited ? downloadEditedTranscript : downloadTranscript;
    const path = edited ? split.editedTranscript.s3FilePath : split.transcript.s3FilePath;
    const file = await download(split.id);
    doDownload(getFileName(path), file);
  };

  const handleTranscriptUpload = async (file) => {
    try {
      setLoading(true);
      const result = await uploadTranscript(split.id, file);
      setSplit(result.saved);
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFeedbackChange = ({ target: { name, value } }) => {
    const feedback = { ...split.feedback, [name]: value };
    setSplit({
      ...split,
      feedback
    });
  };

  const handleSplitFeedbackSave = async () => {
    try {
      setLoading(true);
      const result = await saveSplit(split);
      setSplit(result.saved);
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClaimChange = ({ target: { name, value } }) => {
    const claim = { ...split.claim, [name]: value };
    setSplit({
      ...split,
      claim
    });
  };

  const handleDisclaimerAccepted = async () => {
    let leaving = false;
    try {
      setLoading(true);
      split.claim.submissionDate = moment();
      await saveSplit(split);
      leaving = true;
      appContext.showNotification('success', 'Split Complete.');
      props.history.replace('/splits');
    } catch (error) {
      split.claim.submissionDate = null;
      appContext.showNotification('error', error.message);
    } finally {
      if (!leaving) setLoading(false);
    }
  };

  const handleSubmitWrongLanguage = async (body) => {
    setLoading(true);
    try {
      const { saved } = await updateWrongLanguage(split.interaction.id, body);
      setSplit({ ...split, interaction: saved });
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <LoadingDialog show={loading} />
      {split && (
        <div className='p-8 m-5'>
          <WarningBanner />
          <Paper className='pb-8'>
            <div className='flex flex-row items-center'>
              <Heading
                title={`Split ${split.position} (${secondsToHhmmss(
                  split.startTimeSecond
                )}) for Interaction ${split.interaction.alphasightsId}`}
                className='p-5'
              />
              {['Completed', 'Transcribed', 'Graded'].includes(split.state) &&
                split.claim?.extensions?.length > 0 && (
                  <div className='w-1/2 flex justify-start pl-6'>
                    <ClaimExtensionsViewer claimExtensions={split.claim.extensions} />
                  </div>
                )}
            </div>
            <div className='p-5 flex flex-row flex-wrap justify-around'>
              {split.state === 'Claimed' && (
                <Fragment>
                  <div className='w-full flex justify-start items-center ml-10'>
                    <Deadline
                      claim={split.claim}
                      hardDeadlineOffset={SPLIT_HARD_DEADLINE_OFFSET}
                      onChangeClick={isAdmin(appContext.auth.role) ? handleDeadlineChange : null}
                      showDetails={['Transcriber', 'TRQA'].includes(appContext.auth.role)}
                      extendable={isDeadlineExtendable()}
                      extensionsViewable
                    />
                    {isAdmin(appContext.auth.role) && (
                      <div className='ml-5 mt-4'>
                        <Button variant='outlined' onClick={handleSplitCancel}>
                          Cancel
                        </Button>
                      </div>
                    )}
                  </div>
                </Fragment>
              )}
              <div className='field'>
                <div className='field-label'>Time range:</div>
                <div className='field-value'>
                  <div>
                    {`${secondsToHhmmss(split.startTimeSecond)} - ${secondsToHhmmss(
                      split.endTimeSecond
                    )}`}
                  </div>
                </div>
              </div>
              <div className='field'>
                <div className='field-label'>Interaction Id:</div>
                <div className='field-value'>{split.interaction.alphasightsId}</div>
              </div>
              <div className='field'>
                <div className='field-label'>Project Id:</div>
                <div className='field-value'>{split.interaction.projectId}</div>
              </div>
              <div className='field'>
                <div className='field-label'>Project Topic:</div>
                <div className='field-value'>{split.interaction.projectTopic}</div>
              </div>
              {isNonEnglish(split.interaction) && (
                <Fragment>
                  <div className='field'>
                    <div className='field-label'>Source Language:</div>
                    <div className='field-value'>{split.interaction.sourceLanguage}</div>
                  </div>
                  <div className='field'>
                    <div className='field-label'>Target Language:</div>
                    <div className='field-value'>{split.interaction.targetLanguage}</div>
                  </div>
                </Fragment>
              )}
              <Button
                variant='outlined'
                href={`/api/interactions/audio/${split.interaction.id}`}
                startIcon={<MicIcon />}
                size='large'
                disabled={!isAdmin(appContext.auth.role) && split.state !== 'Claimed'}
                download>
                Audio file
              </Button>
              <div className='w-4/5'>
                <TextField
                  label='Interaction Details'
                  value={split.interaction.notes}
                  multiline
                  variant='outlined'
                  inputProps={{ readOnly: true }}
                />
              </div>
            </div>
            <WrongLanguage
              handleSubmit={handleSubmitWrongLanguage}
              isAdmin={isAdmin(appContext.auth.role)}
              isWrongLanguage={split.interaction.isWrongLanguage}
              loadedReason={split.interaction.wrongLanguageReason || ''}
            />
          </Paper>
          <Feedback
            feedback={split.feedback || {}}
            handleChange={handleFeedbackChange}
            handleSave={handleSplitFeedbackSave}
            className='p-5'
            disabled={split.state !== 'Claimed'}
            provideAccents={!isNonEnglish(split.interaction) && split.interaction.fullFile}
          />
          <Transcript
            transcript={split.transcript}
            handleUpload={split.state === 'Claimed' ? handleTranscriptUpload : null}
            handleDownload={() => handleDownloadTranscript(false)}
            className='p-5'
            disabled={
              !split.feedback ||
              !split.feedback.id ||
              (split.transcript && isTranscriptExpired(split.transcript.transcriptDate))
            }
          />
          {split.editedTranscript && interactionCompleted(split.interaction.state) && (
            <Transcript
              transcript={split.editedTranscript}
              handleDownload={() => handleDownloadTranscript(true)}
              className='p-5'
              disabled={isTranscriptExpired(split.editedTranscript.transcriptDate)}
            />
          )}
          {split.claim &&
            (split.claim.delayReason ||
              (moment(split.claim.finalEyeDeadline) < moment() && !split.claim.submissionDate)) && (
              <DelayReason
                delayReason={split.claim.delayReason}
                onDelayReasonChange={handleClaimChange}
                className='p-5'
              />
            )}
          {split.state === 'Claimed' && (
            <Disclaimer
              onAccepted={handleDisclaimerAccepted}
              className='p-5'
              disabled={
                !split.feedback || !split.feedback.id || !split.transcript || !split.transcript.id
              }
            />
          )}
          {split.assessment && interactionCompleted(split.interaction.state) && (
            <Assessment assessment={split.assessment} className='p-5' />
          )}
        </div>
      )}
    </div>
  );
};

export default SplitDetails;
