import { get, post, patch, getPagedSorted, download, postFile } from "services/ServiceBase";
import {
  ORIGINAL_TRANSCRIPT_KEY,
  PROCESSED_TRANSCRIPT_KEY,
  assetTypes
} from "../components/AITranscriptFiles/constants";

export const getInteractions = (pagination, sort, filterState, englishOnly) => {
  const engOnly = englishOnly === null ? "" : englishOnly;
  const state = filterState === "All" ? "" : filterState;

  if (filterState === "All" && englishOnly === null) {
    return getPagedSorted("/api/interactions?", pagination, sort);
  }
  return getPagedSorted(
    `/api/interactions/filter?state=${state}&englishOnly=${engOnly}&`,
    pagination,
    sort
  );
};

export const getClaimableInteractions = () => get("/api/interactions/claimable");

export const getClaimedInteractions = () => get("/api/interactions/claimed");

export const getAssignedInteractions = () => get("/api/interactions/assigned");

export const getInteraction = (id) => get(`/api/interactions/detail/${id}`);

export const getQaInteractions = (qaId, pagination, sort, searchString) =>
  getPagedSorted(
    `/api/interactions/qa/${qaId}?searchString=${encodeURIComponent(searchString)}&`,
    pagination,
    sort
  );

export const claimInteraction = (id) => post(`/api/interactions/claim/${id}`, {});

export const cancelInteractionClaim = (id) => post(`/api/interactions/cancel/claim/${id}`, {});

export const downloadTranscript = (id) => download(`/api/interactions/download/${id}`);

export const downloadEditedTranscript = (id) => download(`/api/interactions/download/edited/${id}`);

export const downloadOriginalTranscript = (id) =>
  download(`/api/transcripts/interaction/${id}/${assetTypes[ORIGINAL_TRANSCRIPT_KEY]}/download`);

export const downloadProcessedTranscript = (id) =>
  download(`/api/transcripts/interaction/${id}/${assetTypes[PROCESSED_TRANSCRIPT_KEY]}/download`);

export const uploadTranscript = (id, file) => postFile(`/api/interactions/upload/${id}`, file);

export const uploadEditedTranscript = (id, file) =>
  postFile(`/api/interactions/upload/edited/${id}`, file);

export const saveInteraction = (interaction) => {
  const url = interaction.id ? `/api/interactions/save/${interaction.id}` : "/api/interactions/new";
  return post(url, interaction);
};

export const submitInteraction = ({ id, isInaudible }, delayReason) =>
  patch(`/api/interaction/${id}/submit`, {
    isInaudible,
    delayReason
  });

export const cancelInteraction = (id) => post(`/api/interactions/cancel/${id}`);

export const suspendInteraction = (id) => post(`/api/interactions/suspend/${id}`);

export const resumeInteraction = (id) => post(`/api/interactions/resume/${id}`);

export const updateGptTranscriptQuality = (id, body) =>
  post(`/api/interactions/gpt-transcript-quality/${id}`, body);

export const updateWrongLanguage = (id, body) =>
  patch(`/api/interaction/${id}/update-wrong-language`, body);

export const stateToColorClasses = (state) => {
  switch (state) {
    case "New":
      return {
        primary: "state-color-new",
        secondary: "state-color-new-secondary"
      };
    case "Transcribing":
      return {
        primary: "state-color-inProgress",
        secondary: "state-color-inProgress-secondary"
      };
    case "InProgressQA":
    case "InProgressCorrector":
    case "InProgressProofreader":
      return {
        primary: "state-color-qa-claimed",
        secondary: "state-color-qa-claimed"
      };
    case "ReadyForQA":
    case "ReadyForCorrector":
    case "ReadyForProofreader":
      return {
        primary: "state-color-actionable",
        secondary: "state-color-actionable-secondary"
      };
    case "Completed":
      return {
        primary: "state-color-complete",
        secondary: "state-color-complete-secondary"
      };
    case "Cancelled":
      return {
        primary: "state-color-cancel",
        secondary: "state-color-cancel-secondary"
      };
    case "Invalid":
      return {
        primary: "state-color-error",
        secondary: "state-color-error-secondary"
      };
    default:
      return {
        primary: "",
        secondary: ""
      };
  }
};
